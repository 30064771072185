<script>
import { useMeta } from 'vue-meta'
import Menu from '@/components/Tiger_Q3/Menu'

export default {
	name: "TigerQ3_info",
	data() {
		return {
		};
	},
	setup () {
		useMeta(
		{ 
			title: '虎牌啤酒 抽高雄全家海神周邊商品',
			description: '2024/4/17 - 5/14至指定通路，購買虎牌啤酒全系列，登錄發票就抽高雄全家海神隊周邊商品!!!' 
		})		
	},
	components: {
		Menu
	},
	computed: {
	},
	watch: {
	},
	mounted() {
	},
	methods: {
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
